import React, { useState } from "react"
import { Link } from "gatsby"
import { useMutation } from '@apollo/react-hooks';
import uuid4 from "uuid/v4"
import {
  Container,
  Button,
  Alert,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"
import { SEND_PASSWORD_RESET_EMAIL } from "../../graphql/mutations"
import { useForgotPasswordResetEmail } from "../../hooks"

const ForgotPasswordForm = () => {
  const [
    SendPasswordResetEmail,
    {
      data: sendPasswordResetData,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(SEND_PASSWORD_RESET_EMAIL)

  const [, setValidate] = useState(false)
  const [username, setUsername] = useState("")
  const [error, setError] = useState("")

  const sendResetEmail = useForgotPasswordResetEmail()
  const handleSendResetEmail = async event => {
    event.preventDefault()
    setValidate(true)
    const result = await sendResetEmail(SendPasswordResetEmail, uuid4, username)
    if (result && result.data && result.data.sendPasswordResetEmail) {
      handleSendResetEmailSuccess()
    } else {
      handleSendResetEmailFail(result)
    }
  }

  const handleSendResetEmailSuccess = () => {
    setValidate(false)
    setUsername("")
  }

  const handleSendResetEmailFail = err => {
    const newError = err
      .split("_")
      .join(" ")
      .toUpperCase()
    setValidate(true)
    setError(newError)
  }

  const handleUsername = username => {
    setUsername(username)
  }

  const renderMessage = (loading, err, user) => {
    if (err) {
      return <Alert color="danger">{error}</Alert>
    } else if (loading) {
      return <Alert color="primary">Loading...</Alert>
    } else if (user) {
      return <Alert color="success">Reset Password Link Sent!</Alert>
    }
  }

  return (
    <Container>
      <Form
        method="POST"
        noValidate
        // validated={validate}
        onSubmit={event => handleSendResetEmail(event)}
      >
        <h2>Gatsby + WordPress Authentication</h2>
        <FormGroup>
          <Label for="username">Username</Label>
          <Input
            type="text"
            name="username"
            id="username"
            required
            placeholder="Username / Email"
            onChange={event => handleUsername(event.target.value)}
            value={username}
          />
          <FormFeedback>Username cannot be empty!</FormFeedback>
        </FormGroup>
        {renderMessage(mutationLoading, mutationError, sendPasswordResetData)}
        <Button color="primary" type="submit">
          Send Reset Password
        </Button>
      </Form>
      <Row className="mt-3">
        <Col>
          <Link to="/login" className="btn btn-primary">
            Remember Password?
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default ForgotPasswordForm
