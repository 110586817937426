import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ForgotPasswordForm from "../components/Forms/ForgotPasswordForm"
import { Container } from "reactstrap"

const ForgotPassword = () => (
  <Layout location="login">
    <SEO title="Login" />
    <Container>
      <h1 class="section-header">Login</h1>
      <ForgotPasswordForm />
    </Container>
  </Layout>
)

export default ForgotPassword
